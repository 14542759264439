import styles from "./LinkButton.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function LinkButton(props) {
  const style = {
    marginRight: "9px",
    marginTop: "3px",
    fontSize: "26px",
    color: "#1b91cf"
  }

  return <a href={props.url} className={styles.linkButton} target={props.target}>
    {props.icon && <FontAwesomeIcon icon={props.icon} style={style} />}
    {props.name}
  </a>
}
