import Navigation from "../Navigation/Navigation";
import SocialBar from "../Social/SocialBar";
import styles from "./MainSection.module.css";

export default function MainSection() {
  const isArgEnabled = false;
  const argText = '🦊';

  const handleCopy = event => {
    event.preventDefault();
    event.clipboardData.setData('text/plain', argText);
  }

  return <main>
    <Navigation />
    <p className={styles.name} onCopy={isArgEnabled ? handleCopy : null}>インターネット上ではすべてがうまくいきます</p>
    <SocialBar />
  </main>
}
