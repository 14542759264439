import SocialTile from "./SocialTile";
import styles from "./SocialBar.module.css";
import { faVk } from '@fortawesome/free-brands-svg-icons';
import { faTelegram } from '@fortawesome/free-brands-svg-icons';
import { faReddit } from '@fortawesome/free-brands-svg-icons';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { faS } from '@fortawesome/free-solid-svg-icons';

export default function SocialBar() {
  const socialLinks = [
    { icon: faVk, url: "https://vk.com/silenthouse49406", color: "#0077ff" },
    { icon: faTelegram, url: "https://t.me/runschat", color: "#1b91cf" },
    { icon: faDiscord, url: "https://discord.com/invite/kDygPTm", color: "#7289da" },
    { icon: faGithub, url: "https://github.com/scanlab49406", color: "#8c8c8c" },
    { icon: faS, url: "https://spaces.im/soo/netstalking", color: "#aa84d6" },
    { icon: faReddit, url: "https://www.reddit.com/r/netstalking", color: "#ff5700" },
  ]

  return <div className={styles.socialBarContainer}>
    <div className={styles.socialBar}>
      {socialLinks.map(link => <SocialTile icon={link.icon} url={link.url} color={link.color} key={link.url} />)}
    </div>
  </div>
}
