import { useEffect, useState } from "react";
import { useSwipeable } from "react-swipeable";
import { CSSTransition } from 'react-transition-group';
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import MainSection from "./components/MainSection/MainSection";
import MobileNavigation from "./components/Navigation/MobileNavigation";
import LanguageSelector from "./components/UI/LanguageSelector/LanguageSelector";
import SwipeArrows from "./components/UI/SwipeArrows/SwipeArrows";
import ProfileWidget from "./components/ProfileWidget/ProfileWidget";
import Terminal from "./components/Terminal/Terminal";
import GMNavigation from "./components/GMNavigation/GMNavigation";
import './App.css';

const DEBUG_PROFILE = false;

const bgImages = [
  "bg01.jpeg",
  "bg06.jpeg",
  "bg07.jpeg",
  "bg08.jpeg",
  "bg09.jpeg",
  "bg10.jpeg",
  "bg11.jpeg",
  "bg02.jpg",
  "bg03.jpg",
  "bg04.jpg",
  "bg05.jpg",
  "bg02.gif",
  "bg03.gif",
  "bg05.gif",
  "bg06.gif",
  "bg07.gif",
  "bg12.jpeg",
  "bg13.jpg",
]

const randomIndex = Math.floor(Math.random() * bgImages.length);
const randomBgImage = bgImages[randomIndex];
const bgi = require(`./assets/bg/${randomBgImage}`);

function App() {
  const [swipeStatus, setSwipeStatus] = useState(false);
  const [isTerminalOpen, setIsTerminalOpen] = useState(false);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === '`' || event.key === "ё") {
        event.preventDefault();
        setIsTerminalOpen(prev => !prev);
      }
      if (event.key === "Escape" && isTerminalOpen) {
        setIsTerminalOpen(false)
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [isTerminalOpen]);

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      setSwipeStatus(true);
    },
    onSwipedRight: () => {
      setSwipeStatus(false)
    }
  });
  const gmMode = localStorage.getItem("gm");

  return (
    <I18nextProvider i18n={i18n}>
    <div className="App" {...handlers}>
      <img src={bgi} className="video-background" alt=""/>

      <div className="video-overlay"></div>
      {isTerminalOpen && <Terminal />}
      {gmMode && <GMNavigation />}
      <LanguageSelector />
      {DEBUG_PROFILE && <ProfileWidget />}
      <CSSTransition
        in={swipeStatus}
        classNames="fade"
        timeout={250}
      >
        {swipeStatus ? <MobileNavigation /> : <MainSection />}
      </CSSTransition>

      <SwipeArrows direction={swipeStatus ? 'right' : 'left'} />
    </div>
    </I18nextProvider>
  );
}

export default App;
