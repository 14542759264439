import { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from "./SocialTile.module.css";


export default function SocialTile(props) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);

  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const elementStyle = {
    backgroundColor: isHovered ? props.color : 'transparent',
    color: isHovered ? "white" : props.color,
  };

  return <a
    href={props.url}
    className={styles.tile}
    style={elementStyle}
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
    target="_blank">
      <FontAwesomeIcon icon={props.icon} />
    </a>
}
